var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"package relative border border-gray-200 rounded mb-4"},[_c('div',{staticClass:"flex justify-between w-full cursor-pointer",class:{
      'opacity-50': _vm.displayStatus === 100,
      'bg-red-100': _vm.isExpired, 
      'bg-green-100':_vm.isAvailable,
    },on:{"click":_vm.toggle}},[_c('div',{staticClass:"pl-4 flex-1 flex items-center"},[_c('BoxSeam'),_vm._v(" "),_c('span',{staticClass:"font-medium ml-2"},[_vm._v(_vm._s(_vm.$t('show.package'))+" "+_vm._s(_vm.i))])],1),_c('div',{staticClass:"p-2 flex-1"},[_c('span',{staticClass:"text-xs text-gray-600"},[_vm._v(_vm._s(_vm.$t('show.status')))]),_c('span',{staticClass:"text-sm font-medium",class:{
        'text-green-600': _vm.displayStatus >= 90,
        'text-red-600': _vm.pack.status < 0,
      }},[_vm._v(" "+_vm._s(_vm._f("ucfirst")(_vm.$t(("status.short." + _vm.displayStatus))))+" "),(_vm.pack.rerouted_to)?_c('span',{staticClass:"text-yellow-700"},[_vm._v(" "+_vm._s(_vm.$t('show.rerouted'))+" ")]):_vm._e()]),_c('br'),(_vm.pack.consolidation)?[_c('span',{staticClass:"text-xs text-gray-600"},[_vm._v(_vm._s(_vm.$t('show.consolidation.title')))]),_c('span',{staticClass:"text-sm font-medium text-yellow-700"},[_vm._v(" "+_vm._s(_vm.$t("show.consolidation.text", {count: _vm.pack.consolidation + 1 }))+" ")])]:[_c('span',{staticClass:"text-xs text-gray-600"},[_vm._v(_vm._s(_vm.$t('show.size')))]),_c('span',{staticClass:"text-sm font-medium"},[_vm._v(" "+_vm._s(_vm._f("ucfirst")(_vm.$t(("show.sizes." + (_vm.pack.size)))))+" ")])]],2),_c('div',{staticClass:"flex p-4 justify-center items-center"},[_c('ChevronDown',{staticClass:"transform transition-transform",class:{'rotate-180': _vm.open}})],1)]),_c('div',{ref:"history",staticClass:"overflow-hidden h-0 opacity-0 transition-height",class:{'opacity-100': _vm.open},style:(_vm.open ? 'height:'+_vm.$refs.history.scrollHeight + 'px' : '')},[(_vm.showLocation && !_vm.isExpired)?_c('Location',{staticClass:"p-4 border-t border-gray-200",attrs:{"location":_vm.pack.rerouted_to || _vm.mainLocation}}):_vm._e(),_c('div',{staticClass:"p-4 border-t border-gray-200"},[_c('h2',{staticClass:"text-sm mb-4 font-medium"},[_vm._v(_vm._s(_vm.$t('show.delivery-history')))]),_c('History',{attrs:{"events":_vm._f("eventFilter")(_vm.pack.events)}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DefaultLayout',[(_vm.loading)?_c('Spinner'):_vm._e(),(_vm.shipment)?_c('div',{staticClass:"content bg-white min-h-full shadow"},[(_vm.single && !_vm.singleExpired)?_c('Timeline',_vm._b({},'Timeline',{displayStatus: _vm.displayStatus},false)):_vm._e(),_c('div',{staticClass:"p-4 md:p-12"},[_c('h1',{staticClass:"text-lg md:text-3xl mb-8"},[_c('BoxSeam',{staticClass:"inline mb-1 mr-1",attrs:{"size":20}}),_vm._v(" "+_vm._s(_vm.$t('show.shipment-from'))+" "),_c('span',{staticClass:"font-semibold"},[_vm._v(_vm._s(this.shipment.merchant_name))])],1),(_vm.trackingNumber !== _vm.shipment.reference_id)?_c('Alert',{staticClass:"mb-4",attrs:{"alert":{
          type: 'info',
          title: _vm.$t('show.consolidated.title'),
          text: _vm.$t('show.consolidated.text',{
            original: _vm.trackingNumber,
            consolidated: _vm.shipment.reference_id,
          }),
        }}}):_vm._e(),_vm._l((_vm.shipment.consolidations),function(reference_id,barcode){return _c('Alert',{key:barcode,staticClass:"mb-4 cursor-pointer",attrs:{"alert":{
          type: 'info',
          title: _vm.$t('show.consolidated.title'),
          text: _vm.$t('show.consolidated-other.text',{reference_id: reference_id, barcode: barcode}),
        }},on:{"click":function($event){return _vm.openShipment(reference_id)}}})}),_c('Info',{staticClass:"-m-2 mb-4"},[_c('InfoItem',{attrs:{"label":_vm.$t('show.reference-id'),"value":_vm.shipment.reference_id}}),_c('InfoItem',{attrs:{"label":_vm.$t('show.external-reference-id'),"value":_vm.shipment.external_reference_id}}),_c('InfoItem',{attrs:{"label":_vm.$t('show.status'),"value":_vm.$t(("status.short." + _vm.displayStatus))}}),(_vm.eta)?_c('InfoItem',{attrs:{"label":_vm.$t('show.estimated-delivery'),"value":_vm.formatDate(_vm.eta)}}):_vm._e()],1),_vm._l((_vm.alerts),function(alert,i){return _c('Alert',{key:'alert-'+i,staticClass:"mb-4",attrs:{"alert":alert}})}),(_vm.singleLocation && !_vm.singleExpired)?_c('div',{staticClass:"pt-4 border-t border-gray-200"},[_c('Location',{staticClass:"mb-4",attrs:{"location":_vm.shipment.dropoff_location}})],1):_vm._e(),_c('div',{staticClass:"pt-4 border-t border-gray-200"},[_c('h2',{staticClass:"text-xl mb-4"},[_vm._v(_vm._s(_vm.$t('show.more-info')))]),_c('Info',{staticClass:"-m-2 mb-6"},[_c('InfoItem',{attrs:{"label":_vm.$t('show.created'),"value":_vm._f("date")(_vm.shipment.created_at)}}),_c('InfoItem',{attrs:{"label":_vm.$t('show.shipment-type'),"value":_vm.$t(("show.types." + (_vm.shipment.type)))}}),(_vm.shipment.contents)?_c('InfoItem',{attrs:{"label":_vm.$t('show.contents'),"value":_vm.shipment.contents}}):_vm._e()],1)],1),(_vm.single)?[(_vm.shipment.packages[0].consolidation)?_c('Alert',{staticClass:"mb-4",attrs:{"alert":{
            type: 'info',
            title: _vm.$t('show.consolidated.title'),
            text: _vm.$t('show.consolidated.info',{
              count: _vm.shipment.packages[0].consolidation + 1 ,
            }),
          }}}):_vm._e(),_c('div',{staticClass:"pt-4 border-t border-gray-200"},[_c('h2',{staticClass:"text-xl mb-4"},[_vm._v(_vm._s(_vm.$t('show.delivery-history')))]),_c('History',{attrs:{"events":_vm._f("eventFilter")(_vm.shipment.events)}})],1)]:_vm._l((_vm.shipment.packages),function(pack,i){return _c('Package',{key:'package-'+i,attrs:{"i":i+1,"pack":pack,"show-location":!_vm.singleLocation,"main-location":_vm.shipment.dropoff_location}})})],2)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
export const ucfirst = ([first, ...rest]) => first && first.toUpperCase() + rest.join('')

export const STATUS_LOST              = -200
export const STATUS_RETURNED          = -100
export const STATUS_CANCELLED         = -90
export const STATUS_EXPIRED_COLLECTED = -50
export const STATUS_EXPIRED           = -40
export const STATUS_DRAFT             = 0
export const STATUS_REGISTERED        = 20
export const STATUS_AWAITING_PICKUP   = 30
export const STATUS_PICKED_UP         = 50
export const STATUS_IN_WAREHOUSE      = 60
export const STATUS_DROPPED_OFF       = 70
export const STATUS_AWAITING_PAYMENT  = 80
export const STATUS_READY_TO_COLLECT  = 90
export const STATUS_COLLECTED         = 100

export const allowedStatuses = [
  STATUS_DRAFT,
  STATUS_REGISTERED,
  STATUS_PICKED_UP,
  STATUS_READY_TO_COLLECT,
  STATUS_COLLECTED
]

export const eventFilter = (events) => {
  return events.filter(event => {
    if(event.type == 'status' && ![STATUS_EXPIRED_COLLECTED,...allowedStatuses].includes(parseInt(event.data))) return false;
    return true
  })
}
<template>
  <DefaultLayout>
    <Spinner v-if="loading" />
    <div v-if="shipment"  class="content bg-white min-h-full shadow">

      <Timeline v-if="single && !singleExpired" v-bind="{displayStatus}" class="" />

      <div class="p-4 md:p-12">

        <h1 class="text-lg md:text-3xl mb-8">
          <BoxSeam :size="20" class="inline mb-1 mr-1" />
          {{$t('show.shipment-from')}} <span class="font-semibold">{{this.shipment.merchant_name}}</span>
        </h1>

        <Alert 
          v-if="trackingNumber !== shipment.reference_id"
          :alert="{
            type: 'info',
            title: $t('show.consolidated.title'),
            text: $t('show.consolidated.text',{
              original: trackingNumber,
              consolidated: shipment.reference_id,
            }),
          }"
          class="mb-4" 
        />

        <Alert 
          v-for="(reference_id, barcode) in shipment.consolidations"
          :key="barcode"
          :alert="{
            type: 'info',
            title: $t('show.consolidated.title'),
            text: $t('show.consolidated-other.text',{reference_id, barcode}),
          }"
          class="mb-4 cursor-pointer" 
          @click="openShipment(reference_id)"
        />

        <Info class="-m-2 mb-4">
          <InfoItem :label="$t('show.reference-id')" :value="shipment.reference_id" />
          <InfoItem :label="$t('show.external-reference-id')" :value="shipment.external_reference_id" />
          <InfoItem :label="$t('show.status')" :value="$t(`status.short.${displayStatus}`)" />
          <InfoItem v-if="eta" :label="$t('show.estimated-delivery')" :value="formatDate(eta)" />
        </Info>

        <Alert 
          v-for="(alert,i) in alerts"
          :key="'alert-'+i"
          :alert="alert"
          class="mb-4" 
        />

        <div v-if="singleLocation && !singleExpired" class="pt-4 border-t border-gray-200">
          <Location :location="shipment.dropoff_location" class="mb-4" />
        </div>

        <div class="pt-4 border-t border-gray-200">
          <h2 class="text-xl mb-4">{{$t('show.more-info')}}</h2>

          <Info class="-m-2 mb-6">
            <InfoItem :label="$t('show.created')" :value="shipment.created_at | date" />
            <InfoItem :label="$t('show.shipment-type')" :value="$t(`show.types.${shipment.type}`)" />
            <!-- <InfoItem :label="$t('show.order-description')" :value="description" /> -->
            <InfoItem v-if="shipment.contents" :label="$t('show.contents')" :value="shipment.contents" />
          </Info>
        </div>

        <template v-if="single">
          <Alert 
            v-if="shipment.packages[0].consolidation"
            :alert="{
              type: 'info',
              title: $t('show.consolidated.title'),
              text: $t('show.consolidated.info',{
                count: shipment.packages[0].consolidation + 1 ,
              }),
            }"
            class="mb-4" 
          />

          <div class="pt-4 border-t border-gray-200">
            <h2 class="text-xl mb-4">{{$t('show.delivery-history')}}</h2>
            <History :events="shipment.events | eventFilter" />
          </div>
        </template>

        <template v-else>
          <Package v-for="(pack,i) in shipment.packages" :key="'package-'+i" :i="i+1" :pack="pack" :show-location="!singleLocation" :main-location="shipment.dropoff_location" />
        </template>

      </div>
    </div>
  </DefaultLayout>
</template>

<script>
import router from '../router'
import api from '../api'

import DefaultLayout from '../layouts/Default.vue'
import Spinner from '../components/Spinner.vue'
import BoxSeam from '../icons/BoxSeam.vue'
import Info from '../components/Info.vue'
import InfoItem from '../components/InfoItem.vue'
import Timeline from '../components/Timeline.vue'
import History from '../components/History.vue'
import Package from '../components/Package.vue'
import Location from '../components/Location.vue'
import Alert from '../components/Alert.vue'
import dayjs from 'dayjs'
import { allowedStatuses, eventFilter, STATUS_EXPIRED, STATUS_EXPIRED_COLLECTED, STATUS_READY_TO_COLLECT } from '../utils'
import Success from '../alerts/Success'
import Error from '../alerts/Error'
import Warning from '../alerts/Warning'

export default {
  name: 'Show',
  components: {
    DefaultLayout,
    Spinner,
    Alert,
    BoxSeam,
    Info,
    InfoItem,
    Timeline,
    History,
    Package,
    Location
  },
  props: {
    trackingNumber: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      alerts: [],
      loading: true,
      shipment: null,
    }
  },
  async created() {
    await this.fetch()
    this.setAlerts()
  },
  watch: {
    async trackingNumber() {
      this.loading = true
      await this.fetch()
      this.setAlerts()
    }
  },
  filters: { eventFilter },
  computed: {
    single() {
      return this.shipment.packages.length === 1
    },
    singleLocation() {
      const [reroutes] = this.reroutes
      return this.single || !reroutes
    },
    singleExpired() {
      const [expired] = this.expired
      return this.single && expired
    },
    status() {
      return this.shipment.status
    },
    displayStatus() {
      if(this.shipment.status === STATUS_EXPIRED) {
        return STATUS_READY_TO_COLLECT
      } else if(this.shipment.status === STATUS_EXPIRED_COLLECTED) {
        return STATUS_EXPIRED
      } else {
        return Math.max(...allowedStatuses.filter(s => s <= this.shipment.status))
      }
    },
    eta() {
      if(!this.shipment.eta) return null

      return dayjs(this.shipment.eta)
    },
    description() {
      return Object.entries(this.shipment.packages
        .reduce((c,p) => (c[p.size] = (c[p.size] || 0) + 1,c),{}))
        .reduce((c,[size,count]) => (c.push(this.$tc('show.description-item',count,{size:this.$t(`show.sizes.${size}`)})),c),[])
        .join(', ')
    },
    reroutes() {
      const totalPackages = this.shipment.packages.length
      const reroutedPackages = this.shipment.packages.filter(p => p.rerouted_to).length

      if(this.shipment.is_rerouted || reroutedPackages === totalPackages) {
        return ['all',totalPackages]
      } else if(reroutedPackages) {
        return ['some',reroutedPackages]
      } else {
        return [false,null]
      }
    },
    expired() {
      const totalPackages = this.shipment.packages.length
      const expiredPackages = this.shipment.packages.filter(p => p.status === STATUS_EXPIRED_COLLECTED).length

      if(expiredPackages === totalPackages) {
        return ['all',totalPackages]
      } else if(expiredPackages) {
        return ['some',expiredPackages]
      } else {
        return [false,null]
      }
    },
    expiresAt() {
      return this.shipment.packages?.[0]?.expires_at
    },
    consolidatedWithOther() {
      return this.shipment.package_count - this.shipment.packages.length
    }
  },
  methods: {
    formatDate(raw) {
      const date = dayjs(raw)
      if(date.isTomorrow()) {
        return this.$t('show.estimated-tomorrow',{date: date.format('LT')});
      } else if(date.isToday()){
        return this.$t('show.estimated-today',{date: date.format('LT')});
      } else {
        return date.format('LLL')
      }
    },
    async fetch() {
      try {
        const {data: {data: shipment}} = await api.get(`track/${this.trackingNumber}`)
        this.shipment = shipment
      } catch (error) {
        router.push({ name: 'search', params: { error: 'not-found' } })
      } finally {
        this.loading = false
      }
    },
    setAlerts() {
      if(this.shipment?.status === STATUS_READY_TO_COLLECT) {
        this.alerts.push(new Success(
          this.$tc('show.alerts.ready.title',this.shipment.packages.length),
          this.$tc('show.alerts.ready.description',this.shipment.packages.length,{date: this.formatDate(this.expiresAt)})
        ))
      } else if(this.shipment?.issue /* Some error*/ ) {
        this.alerts.push(new Error(
          this.$tc('show.alerts.issue.title'),
          this.$tc(`show.alerts.issue.${this.shipment.issue}`)
        ))
      }

      const [hasExpired,expiredCount] = this.expired

      if(hasExpired) {
        this.alerts.push(new Error(
          this.$tc(`show.alerts.expired-${hasExpired}.title`,expiredCount,{merchant: this.shipment?.merchant_name}),
          this.$tc(`show.alerts.expired-${hasExpired}.description`,expiredCount,{merchant: this.shipment?.merchant_name})
        ))
        return
      }

      const [hasReroutes,reroutedCount] = this.reroutes

      if(hasReroutes) {
        this.alerts.push(new Warning(
          this.$tc(`show.alerts.rerouted-${hasReroutes}.title`,reroutedCount),
          this.$tc(`show.alerts.rerouted-${hasReroutes}.description`,reroutedCount)
        ))
      }
    },
    openShipment(trackingNumber) {
      console.log('kaka')
      this.$router.push({ name: 'show', params: { trackingNumber } })
    }
  },
  metaInfo() {
    return {
      title: this.$t('show.title',{merchant: this.shipment?.merchant_name}),
    }
  } 
}
</script>
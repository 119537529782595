<template>
  <div class="package relative border border-gray-200 rounded mb-4">
    <div 
      class="flex justify-between w-full cursor-pointer" 
      :class="{
        'opacity-50': displayStatus === 100,
        'bg-red-100': isExpired, 
        'bg-green-100':isAvailable,
      }" 
      @click="toggle"
    >
      <div class="pl-4 flex-1 flex items-center">
        <BoxSeam /> <span class="font-medium ml-2">{{$t('show.package')}} {{i}}</span>
      </div>
      <div class="p-2 flex-1">
        <span class="text-xs text-gray-600">{{$t('show.status')}}</span>
        <span class="text-sm font-medium" :class="{
          'text-green-600': displayStatus >= 90,
          'text-red-600': pack.status < 0,
        }">
          {{$t(`status.short.${displayStatus}`) | ucfirst}}
          <span v-if="pack.rerouted_to" class="text-yellow-700">
            {{$t('show.rerouted')}}
          </span>
        </span><br>
        <template v-if="pack.consolidation">
          <span class="text-xs text-gray-600">{{$t('show.consolidation.title')}}</span>
          <span class="text-sm font-medium text-yellow-700">
            {{$t(`show.consolidation.text`, {count: pack.consolidation + 1 })}}
          </span>
        </template>
        <template v-else>
          <span class="text-xs text-gray-600">{{$t('show.size')}}</span>
          <span class="text-sm font-medium">
            {{$t(`show.sizes.${pack.size}`) | ucfirst}}
          </span>
        </template>
      </div>
      <div class="flex p-4 justify-center items-center">
        <ChevronDown class="transform transition-transform" :class="{'rotate-180': open}" />
      </div>
    </div>

    <div 
      ref="history"
      class="overflow-hidden h-0 opacity-0 transition-height" 
      :class="{'opacity-100': open}" 
      :style="open ? 'height:'+$refs.history.scrollHeight + 'px' : ''"
    >
      <Location 
        v-if="showLocation && !isExpired" 
        :location="pack.rerouted_to || mainLocation" 
        class="p-4 border-t border-gray-200"
      />
      <div class="p-4 border-t border-gray-200">
        <h2 class="text-sm mb-4 font-medium">{{$t('show.delivery-history')}}</h2>
        <History :events="pack.events | eventFilter" />
      </div>
    </div>
  </div>
</template>

<script>
import BoxSeam from '../icons/BoxSeam.vue'
import ChevronDown from '../icons/ChevronDown.vue'
import Location from '../components/Location.vue'
import History from '../components/History.vue'

import {allowedStatuses, ucfirst, eventFilter, STATUS_EXPIRED, STATUS_READY_TO_COLLECT, STATUS_EXPIRED_COLLECTED} from '../utils'

export default {
  components: {
    BoxSeam,
    ChevronDown,
    Location,
    History,
  },
  filters: { ucfirst, eventFilter },
  props: {
    pack: {
      type: Object,
      required: true
    },
    i: Number,
    mainLocation: {
      type: Object,
      default: null
    },
    showLocation: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      open: false
    }
  },
  computed: {
    displayStatus() {
      if(this.pack.status === STATUS_EXPIRED_COLLECTED) {
        return STATUS_EXPIRED
      } else if(this.pack.status === STATUS_EXPIRED) {
        return STATUS_READY_TO_COLLECT
      } else {
        return Math.max(...allowedStatuses.filter(s => s <= this.pack.status))
      }
    },
    isExpired() {
      return this.pack.status === STATUS_EXPIRED_COLLECTED
    },
    isAvailable() {
      return this.pack.status === STATUS_READY_TO_COLLECT
        || this.pack.status === STATUS_EXPIRED
    }
  },
  methods: {
    toggle() {
      this.open = !this.open
      if(this.open) {
        setTimeout(() => this.$el.scrollIntoView({behavior: "smooth"}),200) // Transition is 150ms
        
      }
    }
  }
}
</script>

<style lang="scss">
  .package {
    scroll-margin-top: calc(72px + 1rem);
  }
</style>